@import '~@anwalt.de/design-system/dist/css/components/navbar-tabs.css';
@import '~@anwalt.de/design-system/dist/css/components/navbar-2021.css';
@import '../../../defaults';

.anw-no-js {
  .anw-offcanvas-2021 {
    ul li a.anw-section-headline::after {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    .navbar-toggler-icon {
      cursor: pointer;
    }
  }
}

@include media-breakpoint-down(sm) {
  .anw-offcanvas-new {
    .anw-highlight-nav-mobile {
      color: $primary !important;
      font-weight: $font-weight-bold !important;
    }
  }
}
